import { Button, Divider, Form } from 'antd'
import { useEffect, useState } from 'react'
import { CountryAsyncSelect } from '@components/AsyncSelect/CountryAsyncSelect'
import { axiosInstance } from 'api'
import { CustomFormData } from './index'
import { MLFormProps } from '../types'
import { useLanguageContext } from '@/contexts/LanguageProvider'
import { prepareOption } from '@components/AsyncSelect/types'
import MultiLanguageInput from '../../components/MultiLanguageInput'
import FloatNumberInput from '@components/FloatNumberInput'
import FormItem from '@components/FormItem'
import { useTranslation } from 'react-i18next'
import FloatInput from '@components/FloatInput'
import FloatTextarea from '@components/FloatTextarea'

const AgencyForm = ({ onSuccess, id, langFields }: MLFormProps) => {
  const [form] = Form.useForm<CustomFormData>()
  const [fullItem, setFullItem] = useState<CustomFormData>()
  const [loading, setLoading] = useState(true)
  const { appLang } = useLanguageContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      axiosInstance
        .get<CustomFormData>(`admin/agencies/${id}`)
        .then(({ data }) => {
          setFullItem({
            ...data,
            countryId: prepareOption(data.country!, appLang),
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [])
  const onFinish = async (values: CustomFormData) => {
    if (id) {
      await axiosInstance.put(`admin/agencies/${id}`, {
        ...values,
        countryId: values.countryId.value || values.countryId,
      })
    } else {
      await axiosInstance.post('admin/agencies', values)
    }
    onSuccess()
  }

  if (loading) return null

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      initialValues={{ commissionRate: 5, ...fullItem }}
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '15px',
      }}
    >
      <MultiLanguageInput
        itemProps={{
          name: 'name',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{ label: t`name`, required: true }}
        languageFields={langFields}
        form={form}
      />
      <MultiLanguageInput
        itemProps={{
          name: 'description',
          hasFeedback: true,
          rules: [{ required: true }],
        }}
        inputProps={{ label: t`labels.Description`, required: true }}
        languageFields={langFields}
        form={form}
      />
      <CountryAsyncSelect
        name="countryId"
        rules={[{ required: true }]}
        withAll={false}
        width="100%"
      />
      <FormItem name="commissionRate">
        <FloatNumberInput label={t`labels.Commission Rate`} min={0} required />
      </FormItem>
      <FormItem name="phone">
        <FloatInput label={t`labels.Phone`} />
      </FormItem>
      <FormItem name="email">
        <FloatInput label={t`labels.Email`} />
      </FormItem>
      <FormItem name="googleTagManagerId">
        <FloatInput label="Google Tag Manager Id" />
      </FormItem>
      <Form.Item
        name="legalInformation"
        hasFeedback
        style={{ gridColumn: 'span 2' }}
      >
        <FloatTextarea label={t`labels.legalInformation`} />
      </Form.Item>
      <div style={{ gridColumn: 'span 2' }}>
        <Divider />
        <Form.Item
          style={{ marginBottom: 0, display: 'flex', justifyContent: 'right' }}
        >
          <Button type="primary" htmlType="submit">
            {t`Submit`}
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default AgencyForm
